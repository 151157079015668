import '@orestbida/iframemanager/dist/iframemanager.js';

const manager = iframemanager();

manager.run({
  currLang: document.documentElement.getAttribute('lang') ?? 'de',
  onChange: ({ changedServices, eventSource }) => {
    if (eventSource.type === 'click') {
      cc.accept(changedServices);
    }
  },
  services: {
    youtube: {
      embedUrl: 'https://www.youtube-nocookie.com/embed/{data-id}',
      thumbnailUrl: 'https://i3.ytimg.com/vi/{data-id}/hqdefault.jpg',
      iframe: {
        allow: 'accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen;',
      },
      cookie: {
        name: 'cc_youtube',
      },
      languages: {
        de: {
          notice:
            'Dieser Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer" href="https://www.youtube.com/t/terms" title="Bedingungen und Konditionen" target="_blank">Bedingungen und Konditionen</a> von youtube.com.',
          loadBtn: 'Video laden',
          loadAllBtn: 'Nicht nochmal fragen',
        },
      },
    },
    vimeo: {
      embedUrl: 'https://player.vimeo.com/video/{data-id}',
      thumbnailUrl: async (dataId, setThumbnail) => {
        const url = `https://vimeo.com/api/v2/video/${dataId}.json`;
        const response = await (await fetch(url)).json();
        const thumbnailUrl = response[0]?.thumbnail_large;
        thumbnailUrl && setThumbnail(thumbnailUrl);
      },
      iframe: {
        allow: 'accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen;',
      },
      cookie: {
        name: 'cc_vimeo',
      },
      languages: {
        de: {
          notice:
            'Dieser Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer noopener" href="https://vimeo.com/terms" target="_blank">Bedingungen und Konditionen</a> von vimeo.com.',
          loadBtn: 'Video laden',
          loadAllBtn: 'Nicht nochmal fragen',
        },
      },
    },
  },
});
